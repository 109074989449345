// store.js
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialogVisible: false // 弹窗是否可见
  },
  mutations: {
    setDialogVisible (state, payload) {
      state.dialogVisible = payload // 改变弹窗的可见性
    }
  },
  actions: {
    openDialog ({ commit }) {
      commit('setDialogVisible', true) // 打开弹窗
    },
    closeDialog ({ commit }) {
      commit('setDialogVisible', false) // 关闭弹窗
    }
  },
  getters: {
    dialogVisible: state => state.dialogVisible // 获取弹窗可见性
  }
})
