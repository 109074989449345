<template>
  <div id="maxBox">
    <div class="detailInfo">
      <el-card>
        <div class="backTop">
          <el-button @click="backToBookList">返回</el-button>
        </div>
        <div class="bookInfo">
          <div class="bookImg"><img :src="book.StrCoverURL" alt="" id="myImage"></div>
          <div class="info">
            <div class="bookTitle">{{ book.StrPositiveTitle }}</div>
            <div class="infoTable">
              <div class="infoTableItem" v-if="book.StrISBN != ''">
                <div class="infoTableItemTitle">ISBN：</div>
                <div class="infoTableItemText">{{ book.StrISBN }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrFirstResponsible != ''">
                <div class="infoTableItemTitle">作者：</div>
                <div class="infoTableItemText">{{ book.StrFirstResponsible }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrPublisher != ''">
                <div class="infoTableItemTitle">出版社：</div>
                <div class="infoTableItemText">{{ book.StrPublisher }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrPublicationDate != ''">
                <div class="infoTableItemTitle">出版时间：</div>
                <div class="infoTableItemText">{{ book.StrPublicationDate }}</div>
              </div>
              <div class="infoTableItem" v-if="book.FloatPricePer != 0.0">
                <div class="infoTableItemTitle">定价：</div>
                <div class="infoTableItemText">{{ book.FloatPricePer }}元</div>
              </div>
              <div class="infoTableItem" v-if="book.StrNumberPages != 0">
                <div class="infoTableItemTitle">页数：</div>
                <div class="infoTableItemText">{{ book.StrNumberPages }}页</div>
              </div>
              <div class="infoTableItem" v-if="book.StrTypeName != ''">
                <div class="infoTableItemTitle">分类号：</div>
                <div class="infoTableItemText">{{ book.StrTypeName }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrCallNumber != ''">
                <div class="infoTableItemTitle">种次号：</div>
                <div class="infoTableItemText">{{ book.StrCallNumber }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="contentValidity" v-if="book.StrSummary != ''">
      <el-card>
        <div class="main_title">内容简介</div>
        <p style="text-indent:2em;">{{ book.StrSummary }}</p>
      </el-card>
    </div>
    <div class="holdingInfo">
      <el-card>
        <div class="main_title">馆藏信息</div>
        <div class=""></div>
        <div class="holdingInfoTable">
          <el-table
            v-loading="loading0" element-loading-text="图书加载中..."
            :data="holdingInfoList"
            style="width: 100%">
            <el-table-column
              v-if="holdingInfoTableShowStrCallNumber"
              prop="StrCallNumber"
              label="种次号">
            </el-table-column>
            <el-table-column
              v-else
              prop="StrTypeName"
              label="索书号">
            </el-table-column>
            <el-table-column
              prop="StrBookBarcode"
              label="条形码">
            </el-table-column>
            <el-table-column
              prop="StrOrganizationName"
              label="所在馆">
            </el-table-column>
            <el-table-column label="馆藏状态">
              <template slot-scope="scope">
                <span>{{ scope.row.IsBorrowed ? '借出' : '在馆' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="IntBorrowedCount"
              label="借阅次数">
            </el-table-column>
            <el-table-column prop="StrBookshelfName" label="书架">
              <template slot-scope="scope">
                <span>{{ scope.row.StrBookshelfName == '' ? '暂无信息' : scope.row.StrBookshelfName }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="StrBookshelfName" label="层架标">
              <template slot-scope="scope">
              <span>{{scope.row.StrBookshelfName=='' ? '暂无信息' : scope.row.StrBookshelfName}}</span>
              </template>
            </el-table-column> -->
            <el-table-column  label="预约">
              <template slot-scope="scope">
              <el-button size="mini" @click="booking(scope.row)">
                预 约
              </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <!--      预约-->
    <el-dialog title="登录" :visible.sync="dialogVisible" width="100%" :before-close="loginCancel">
      <el-form :model="param" :rules="rules" ref="login" label-width="0px">
        <el-form-item prop="num" class="input_class">
          <el-input v-model="param.num" class="w-50 m-2" placeholder="请输入用户名">
            <template #prefix>
              <img src="../assets/img/username.png" style="height: 20px; width: 18px; margin-top: 9px; margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pwd" class="input_class">
          <el-input class="w-50 m-2" placeholder="请输入密码" v-model="param.pwd" @keyup.enter="login()" type="password">
            <template #prefix>
              <img src="../assets/img/passwordIcon.png" style="height: 20px; width: 18px; margin-top: 9px; margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <div id="input_btn" class="input_class" style="margin-top: 60px; margin-bottom: 40px; text-align: center">
          <el-button type="success" @click="login()">登&nbsp;录</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      title="设置"
      :show-close="this.showClose"
      :visible.sync="dialogVisibleSeting"
      width="80%">
      <span style="margin-left: 10px">组织</span>
      <el-select v-model="searchForm1.StrOrganizationIDs[0]" placeholder="请选择组织" clearable style="margin-left: 10px;max-width: 70%" >
        <el-option
          v-for="address in libraryAddresses"
          :key="address.StrID"
          :label="address.StrName"
          :value="address.StrID">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisibleSeting = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sureChange()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 书评 -->
    <!-- <div class="bookReview">
      <el-card>
        <div class="main_title">豆瓣书评</div>
        </el-card>
    </div> -->

    <!-- 网络书店 -->
    <!--      <div class="bookStore">-->
    <!--        <el-card>-->
    <!--          <div class="main_title">网络书店</div>-->
    <!--          <div>-->
    <!--            <div class="bookBuyUrl">-->
    <!--              <a :href="dangdangUrl" target="_blank" >在当当中查看</a>-->
    <!--            </div>-->
    <!--            <div class="bookBuyUrl">-->
    <!--              <a :href="doubanUrl" target="_blank">在豆瓣中查看</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          </el-card>-->
    <!--      </div>-->
    <div class="authorOtherBooks">
      <el-card>
        <div class="main_title">作者其他图书</div>
        <div>
          <el-table v-loading="loading" element-loading-text="图书加载中..."
                    :data="otherBooks"
                    style="width: 100%">
            <el-table-column
              prop="StrPositiveTitle"
              label="书名">
            </el-table-column>
            <el-table-column
              prop="StrFirstResponsible"
              label="作者">
            </el-table-column>
            <el-table-column prop="StrPublisher" label="出版社">
              <template slot-scope="scope">
                  <span>
                    {{ scope.row.StrPublisher == '' ? '暂无信息' : scope.row.StrPublisher }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="StrPublicationDate"
              label="出版日期">
            </el-table-column>
            <el-table-column
              v-if="authorOtherBooksTableShowStrCallNumber"
              prop="StrCallNumber"
              label="种次号">
            </el-table-column>
            <el-table-column
              v-else
              prop="StrTypeName"
              label="索书号">
            </el-table-column>
            <el-table-column prop="StrBookshelfName" label="层架标">
              <template slot-scope="scope">
                  <span>
                    {{ scope.row.StrBookshelfName == '' ? '暂无信息' : scope.row.StrBookshelfName }}
                  </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--          手机端-->
        <div v-if="UseMobile" style="max-width: 100%;height: 35px;overflow: auto">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small hide-on-single-page
                         :current-page="searchOtherBooksForm.Page" :page-size="searchOtherBooksForm.PerPage"
                         layout="pager,jumper" :total="totalBookNum" :pager-count="5">
          </el-pagination>
        </div>
        <!--          电脑端-->
        <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="searchOtherBooksForm.Page" :page-sizes="[10, 20, 30, 50]"
                       :page-size="searchOtherBooksForm.PerPage"
                       hide-on-single-page layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getBooksByConditions, getAuthorOtherBooks, login, reservation, getAllLibs } from '@/api'

export default {
  name: 'detailInfo',
  data () {
    return {
      param: {
        num: '',
        pwd: ''
      },
      rules: {
        num: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      lib: '',
      dialogVisible: false,
      showClose: false,
      dialogVisibleSeting: false,
      searchForm1: {
        KeyName: 'all',
        StrKeywords: '',
        Sort: '-1',
        OrderBy: 'DTCreatedDate',
        OnLib: false,
        Distinct: true,
        Page: 1,
        PerPage: 10,
        StrOrganizationIDs: []
      },
      libraryAddresses: [],
      libName: '',
      settingIsIcon: true,
      holdingInfoTableShowStrCallNumber: true,
      authorOtherBooksTableShowStrCallNumber: true,

      loading: false,
      loading0: false,
      totalBookNum: 0,
      UseMobile: false,
      dangdangUrl: '',
      doubanUrl: '',
      imgUrl: 'https://next.sanyue100.com/covers/',
      holdingInfoList: [],
      searchForm: {},
      searchBooksForm: {},
      book: {},
      otherBooks: [],
      searchOtherBooksForm: {
        Page: 1,
        PerPage: 999
      },
      routerPath: ''
    }
  },
  created () {
    this.UseMobile = document.body.clientWidth < 620

    this.searchForm = Object.assign({}, JSON.parse(this.$route.query.res))
    this.book = Object.assign({}, JSON.parse(this.$route.query.book))
    // this.getImg(this.book)
    this.routerPath = this.$route.query.router
    this.getBooksByISBN()
    this.dangdangUrl = 'http://search.dangdang.com/?key=' + this.book.StrISBN + '\\&act=input'
    this.doubanUrl = 'https://search.douban.com/book/subject_search?search_text=' + this.book.StrISBN
    if (this.book.StrFirstResponsible !== 'NULL' && this.book.StrFirstResponsible !== '') {
      this.authorOtherBooks()
    }
  },
  mounted () {
    this.searchLibraryAddresses()
    this.searchForm1.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    this.searchForm1.StrKeywords = sessionStorage.getItem('searchContent') == null ? '' : sessionStorage.getItem('searchContent')
    const keyname = sessionStorage.getItem('keyName')
    if (keyname !== null && keyname !== '') {
      this.searchForm1.KeyName = keyname
    } else {
      this.searchForm1.KeyName = 'all'
    }
  },
  methods: {
    async searchLibraryAddresses () {
      // const { data: res } = await this.$http.get('search/list')
      // if (res.meta.status !== 200) {
      //   return this.$message.console.error(res.meta.msg)
      // }
      // console.log(res.data)
      // this.libraryAddresses = res.data
      const result = await getAllLibs()
      if (result.ok) {
        this.libraryAddresses = result.msg
        this.changeLib()
      } else {
        return this.$message(result.msg)
      }
    },
    backToBookList () {
      this.$router.push({
        path: this.routerPath,
        query: { res: JSON.stringify(this.searchForm) }
      })
    },
    async getBooksByISBN () {
      this.loading0 = true
      this.searchBooksForm = Object.assign({}, this.searchForm)

      // if(this.searchBooksForm.StrOrganizationID !== undefined){
      //   this.searchBooksForm.StrOrganizationIDs = []
      //   this.searchBooksForm.StrOrganizationIDs[0] = this.searchBooksForm.StrOrganizationID
      // }
      this.searchBooksForm.StrOrganizationIDs = []
      this.searchBooksForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')

      this.searchBooksForm.KeyName = 'StrISBN'
      this.searchBooksForm.StrKeywords = this.book.StrISBN
      this.searchBooksForm.PerPage = this.book.IntNumberCopies
      this.searchBooksForm.Page = 1
      this.searchBooksForm.Distinct = false

      const result = await getBooksByConditions(this.searchBooksForm)
      if (result.ok) {
        this.holdingInfoList = result.msg
        for (var i in this.holdingInfoList) {
          if (this.holdingInfoList[i].StrCallNumber === '') {
            this.holdingInfoTableShowStrCallNumber = false
            break
          }
        }
      } else {
        return this.$message(result.msg)
      }
      this.loading0 = false
    },
    async authorOtherBooks () {
      this.loading = true
      this.searchOtherBooksForm.StrFirstResponsible = this.book.StrFirstResponsible
      this.searchOtherBooksForm.CurrentStrISBN = this.book.StrISBN

      this.searchOtherBooksForm.StrOrganizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')

      const result = await getAuthorOtherBooks(this.searchOtherBooksForm)
      if (result.ok) {
        this.totalBookNum = result.total
        this.otherBooks = result.msg
        for (var i in this.otherBooks) {
          if (this.otherBooks[i].StrCallNumber === '') {
            this.authorOtherBooksTableShowStrCallNumber = false
            break
          }
        }
        // console.log(this.otherBooks)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    // 每页显示条数更改
    handleSizeChange (newSize) {
      this.searchOtherBooksForm.PerPage = newSize
      this.authorOtherBooks()
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchOtherBooksForm.Page = newPage
      this.authorOtherBooks()
    },
    async booking (row) {
      if (localStorage.getItem('token') !== null) {
        const Data = {
          StrBookID: row.StrID
        }
        const response = await reservation(Data)
        if (response.ok) {
          this.message('预约成功')
        } else {
          this.message(response.msg)
        }
      } else if (localStorage.getItem('library') === null || localStorage.getItem('library') === '') {
        // this.$router.push('/index')
        // this.$store.dispatch('openDialog') // 触发 Vuex action 打开弹窗
        this.dialogVisibleSeting = true
      } else {
        this.dialogVisible = true
      }
    },
    unbooking () {
      this.message('非在馆图书无法预约！')
    },
    async login () {
      try {
        if (this.card === '' || this.pwd === '') {
          this.message('不能提交空选项')
          return
        }
        this.searchOtherBooksForm.StrOrganizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
        const loginData = {
          StrOrganizationID: localStorage.getItem('library') == null ? '' : localStorage.getItem('library'),
          StrReaderCardNumber: this.param.num,
          StrPassword: this.param.pwd
        }
        const response = await login(loginData)
        console.log('登录成功', response)
        if (response.ok) {
          localStorage.setItem('token', response.token)
          localStorage.setItem('card', this.param.num)
          this.dialogVisible = false
          await this.message('登录成功')
          await new Promise(resolve => setTimeout(resolve, 1000))
          this.$router.go(0)
          window.location.reload()
        } else {
          this.message('登录失败,' + response.msg)
        }
        // 处理登录成功的逻辑，比如跳转页面或存储用户信息
      } catch (error) {
        console.error('登录失败', error)
        this.message('登录失败,' + error)
        // 处理登录失败的逻辑，比如显示错误信息
      }
    },
    message (msg) {
      const h = this.$createElement
      this.$notify({
        title: '提示',
        message: h('i', { style: 'color: teal' }, msg)
      })
    },
    loginCancel () {
      this.lib = ''
      this.param.pwd = ''
      this.param.num = ''
      this.dialogVisible = false
    },
    sureChange () {
      localStorage.setItem('library', this.searchForm1.StrOrganizationIDs[0])
      this.changeLib()
      this.dialogVisibleSeting = false
    },
    changeLib () {
      this.searchForm1.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
      if (this.searchForm1.StrOrganizationIDs[0] != null && this.searchForm1.StrOrganizationIDs[0] !== '') {
        for (var i in this.libraryAddresses) {
          if (this.libraryAddresses[i].StrID === this.searchForm1.StrOrganizationIDs[0]) {
            this.libName = this.libraryAddresses[i].StrName
            this.settingIsIcon = false
          }
        }
      } else {
        this.libName = ''
        this.settingIsIcon = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-bottom: 20px;
}

.backTop {
  margin-bottom: 20px;
}

.bookInfo {
  display: flex;

  .bookTitle {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.bookImg {
  //margin: 0 40px;
  min-width: 100px;
  height: 140px;
  overflow: hidden;
  padding-top: 20px;
  margin-right: 10px;

  img {
    width: 95px;
    height: 100px;
    object-fit: contain;
  }
}

.infoTableItem {
  font-size: 13px;
  display: flex;
}

.infoTableItemTitle {
  //width: 200px;
  margin-bottom: 4px;
}

.main_title {
  font-size: 20px;
  margin-bottom: 10px;
}

.bookBuyUrl {
  a {
    color: black;
    text-decoration: none;
  }
}

.bookBuyUrl a:hover {
  color: #3f82c4;
}

#maxBox {
  width: 100%;
  margin: 0px auto;
}
/* 手机端登录对话框 */
.login-dialog-mobile {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: 100%;
}

/* 标题样式 */
.login-dialog-mobile .el-dialog__header {
  background: linear-gradient(135deg, #00bcd4, #4caf50);
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 20px 15px;
  text-align: center;
}

/* 对话框的内容区域 */
.dialog-content {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 16px;
  box-sizing: border-box;
}

/* 输入框样式 */
.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
  color: #333;
  font-size: 14px;
}

.input-field {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background: #fff;
}

.input-field:focus {
  border-color: #00bcd4; /* 聚焦时的边框颜色 */
  box-shadow: 0 0 5px rgba(0, 188, 212, 0.5);
}

/* 按钮样式 */
.dialog-footer {
  text-align: center;
  padding: 10px;
}

.cancel-btn {
  margin-right: 12px;
  color: #757575;
  border-color: #ddd;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: 45%;
  margin-bottom: 10px;
}

.confirm-btn {
  background-color: #4caf50;
  border-color: #4caf50;
  font-size: 14px;
  color: white;
  transition: background-color 0.3s ease;
  width: 45%;
  margin-bottom: 10px;
}

.cancel-btn:hover {
  background-color: #f2f2f2;
}

.confirm-btn:hover {
  background-color: #45a049;
}

/* 按钮的焦点效果 */
.cancel-btn:focus,
.confirm-btn:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(0, 188, 212, 0.6);
}

/* 响应式设计，尤其适配手机 */
@media (max-width: 600px) {
  .login-dialog-mobile {
    width: 90%;
    padding: 15px;
  }

  .dialog-content {
    padding: 15px;
  }

  .input-group {
    margin-bottom: 20px;
  }

  .input-field {
    padding: 14px;
    font-size: 16px;
  }

  .cancel-btn, .confirm-btn {
    font-size: 16px;
    width: 35%;
    padding: 14px;
    margin: 10px 0;
    margin-left: 20px;
  }

  .cancel-btn {
    background-color: #f1f1f1;
    border-color: #ddd;
  }

  .confirm-btn {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;
  }

  .dialog-footer {
    text-align: center;
    padding: 0;
  }
}
.input_class {
  width: 322px;
  margin: 30px auto;
}
/deep/ .el-button--success {
  background-color: #4be4d0;
  border-color: #4be4d0;
}

</style>
