<template>
  <div id="app">
    <!-- 读者信息 -->
    <el-card class="info-card">
      <div slot="header" class="clearfix">
        <span>读者信息</span>
      </div>
      <div class="reader-info">
        <el-row >
          <el-col :span="24" sm="8"><strong>姓名：</strong>{{ reader.StrName }}</el-col>
          <el-col :span="24" sm="8"><strong>读者号：</strong>{{ reader.StrReaderCardNumber }} </el-col>
          <el-col :span="24" sm="8"><strong>读者类型：</strong>{{ reader.StrReaderTypeName }}</el-col>
          <el-col :span="24" sm="8"><strong>部门名称：</strong>{{ reader.StrGroupName }}</el-col>
          <el-col :span="24" sm="8"><strong>可借数量：</strong>{{ reader.IntAvailableBookBorrowNum }}</el-col>
          <el-col :span="24" sm="8"><strong>借出数量：</strong>{{ reader.IntBookBorrowNum }}</el-col>

        </el-row>
      </div>
    </el-card>

    <!-- 预约信息 -->
    <el-card class="info-card" style="margin-top: 20px;">
      <div slot="header" class="clearfix">
        <span>预约信息</span>
      </div>
      <el-table :data="formattedReservations" style="width: 100%" border max-height="300px">
        <el-table-column label="预约书籍" prop="BookTitle"></el-table-column>
        <el-table-column label="书籍条码" prop="BookBarcode"></el-table-column>
        <el-table-column label="预约时间" prop="AppointmentTime"></el-table-column>
        <el-table-column label="结束时间" prop="EndAppointmentTime"></el-table-column>
        <el-table-column label="取书时间" prop="ActualPickupTime"></el-table-column>
        <el-table-column label="预约状态" prop="AppointmentStatus"></el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        v-if="bookingTotal > bookingPageSize"
        :current-page="bookingCurrentPage"
        :page-size="bookingPageSize"
        :total="bookingTotal"
        @current-change="handleBookingChange"
        layout="total, prev, pager, next"
        style="text-align: center; margin-top: 10px;"
      ></el-pagination>
    </el-card>

    <!-- 借阅记录 -->
    <el-card class="info-card" style="margin-top: 20px;">
      <div slot="header" class="clearfix">
        <span>借阅记录</span>
      </div>

      <!-- 未还记录 -->
      <div style="margin-top: 20px;">
        <h3>未还记录</h3>
        <el-table :data="formattedUnReturnBorrowRecords" style="width: 100%" border max-height="300px">
          <el-table-column label="书籍名称" prop="StrPositiveTitle"></el-table-column>
          <el-table-column label="书籍条码" prop="StrBookBarcode"></el-table-column>
          <el-table-column label="借阅日期" prop="DTBorrowDate"></el-table-column>
          <el-table-column label="归还日期" prop="DTReturnDate"></el-table-column>
        </el-table>
      </div>

      <!-- 已还记录 -->
      <div>
        <h3>已还记录</h3>
        <el-table :data="formattedReturnBorrowRecords" style="width: 100%" border max-height="300px">
          <el-table-column label="书籍名称" prop="StrPositiveTitle"></el-table-column>
          <el-table-column label="书籍条码" prop="StrBookBarcode"></el-table-column>
          <el-table-column label="借阅日期" prop="DTBorrowDate"></el-table-column>
          <el-table-column label="归还日期" prop="DTReturnDate"></el-table-column>
        </el-table>

        <!-- 分页组件 -->
        <el-pagination
          v-if="totalReturnedRecords > pageSize"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalReturnedRecords"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next"
          style="text-align: center; margin-top: 10px;"
        ></el-pagination>
      </div>

    </el-card>
  </div>
</template>

<script>
import { readerData, readerInfo, reservationInfo } from '@/api'

export default {
  data () {
    return {
      // 读者信息
      reader: {},

      // 预约信息
      reservations: [],
      returnBorrowRecords: [],
      unReturnBorrowRecords: [],
      currentPage: 1,
      pageSize: 10,
      totalReturnedRecords: 0,
      bookingCurrentPage: 1,
      bookingPageSize: 10,
      bookingTotal: 0
    }
  },
  computed: {
    formattedReturnBorrowRecords () {
      return this.returnBorrowRecords.map(record => {
        return {
          ...record,
          DTBorrowDate: new Date(record.DTBorrowDate).toLocaleString(),
          DTReturnDate: new Date(record.DTReturnDate).toLocaleString()
        }
      })
    },
    formattedUnReturnBorrowRecords () {
      return this.unReturnBorrowRecords.map(record => {
        return {
          ...record,
          DTBorrowDate: new Date(record.DTBorrowDate).toLocaleString(),
          DTReturnDate: new Date(record.DTReturnDate).toLocaleString()
        }
      })
    },
    formattedReservations () {
      return this.reservations.map(record => {
        return {
          ...record,
          AppointmentTime: new Date(record.AppointmentTime).toLocaleString(),
          EndAppointmentTime: new Date(record.EndAppointmentTime).toLocaleString(),
          AppointmentStatus: this.formatPickupTime(record.AppointmentStatus),
          ActualPickupTime: this.formatTime(record.ActualPickupTime).toLocaleString()
        }
      })
    }
  },
  mounted () {
    this.fetchData()
    this.readerMe()
    this.fetchBookingData()
  },
  methods: {
    formatTime (value) {
      if (value === '0001-01-01T00:00:00Z') {
        return '——'
      } else {
        return new Date(value).toLocaleString()
      }
    },
    formatPickupTime (value) {
      switch (value) {
        case 0:
          return '预约成功'
        case 1:
          return '预约锁定'
        case 2:
          return '预约完成'
        case 3:
          return '预约取消'
        case 4:
          return '预约超期'
        default:
          return '——'
      }
    },
    async fetchData () {
      await this.loadReturnBorrowRecords(this.currentPage)
    },
    async loadReturnBorrowRecords (page) {
      const Data = {
        Page: page,
        PerPage: this.pageSize
      }
      const response = await readerData(Data)
      if (response.ok) {
        this.returnBorrowRecords = response.msg.returnBorrowRecords
        this.unReturnBorrowRecords = response.msg.unReturnBorrowRecords
        this.totalReturnedRecords = response.msg.total // 确保 API 返回总记录数
      } else {
        this.message('获取数据失败' + response.msg)
      }
    },

    handleCurrentChange (page) {
      this.currentPage = page
      this.fetchData()
    },
    async readerMe () {
      const response = await readerInfo()
      console.log(response)
      if (response.ok) {
        this.reader = response.msg
      } else {
        this.message('获取用户信息失败:' + response.msg)
      }
    },
    async fetchBookingData () {
      await this.reservationBookInfo(this.bookingCurrentPage)
    },
    async reservationBookInfo (page) {
      const data = {
        Page: page,
        PerPage: this.bookingPageSize
      }
      const response = await reservationInfo(data)
      console.log(response)
      if (response.ok) {
        this.reservations = response.msg.datas
        this.bookingTotal = response.msg.total // 确保 API 返回总记录数
      } else {
        this.message('获取预约信息错误' + response.msg)
      }
    },
    handleBookingChange (page) {
      this.bookingCurrentPage = page
      this.fetchBookingData()
    },
    message (msg) {
      const h = this.$createElement
      this.$notify({
        title: '提示',
        message: h('i', { style: 'color: teal' }, msg)
      })
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.info-card {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reader-info {
  margin-top: 20px;
}

.el-card__header {
  background-color: #f5f7fa;
  font-weight: bold;
  font-size: 16px;
}

.el-table {
  margin-top: 10px;
}

.el-table th, .el-table td {
  text-align: center;
}

@media (max-width: 768px) {
  .el-card__header {
    font-size: 14px;
  }

  .el-col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .el-table {
    font-size: 12px;
  }
}
</style>
