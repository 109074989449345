// 统一管理项目接口的模块
// 引入二次封装的axios（带有请求、响应的拦截器）
import requests from './ajax'

// 三级菜单的请求地址  /api/product/getBaseCategoryList   GET    没有任何参数
// 对外暴露一个函数，只要外部调用这个函数，就想服务器发起ajax请求、获取咱们的三级菜单数据。当前咱们这个函数只需要把服务器返回结果返回即可。
export const getAllLibs = () => requests({ url: '/opac/getAllLibs', method: 'get' })
export const getBooksByConditions = (data) => requests({ url: '/opac/getBooksByConditions', method: 'post', data })
export const getBooksOthers = (data) => requests({ url: '/opac/getBooksOthers', method: 'post', data })
export const getAuthorOtherBooks = (data) => requests({ url: '/opac/getAuthorOtherBooks', method: 'post', data })
export const getNewBooks = (data) => requests({ url: '/opac/getNewBooks', method: 'post', data })
export const getHotBooks = (data) => requests({ url: '/opac/getHotBooks', method: 'post', data })
export const getClcType = (data) => requests({ url: '/opac/getClcType', method: 'get', data })

export const login = (data) => {
  return requests({
    url: '/readers/login',
    method: 'post',
    data
  })
}
// 读者的借还记录
export const readerData = (data) => {
  const token = localStorage.getItem('token')
  return requests({
    url: '/readers/myreads',
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
// 读者的个人信息
export const readerInfo = () => {
  const token = localStorage.getItem('token')
  return requests({
    url: '/readers/me',
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

// 预约操作
export const reservation = (data) => {
  const token = localStorage.getItem('token')

  if (!token) {
    console.log('没有token')
  }

  return requests({
    url: '/bookAppointmentRecord/create',
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
// 预约信息
export const reservationInfo = (data) => {
  const token = localStorage.getItem('token')

  if (!token) {
    // 处理没有 token 的情况，比如跳转到登录页面
    console.log('没有token')
  }
  return requests({
    url: '/readers/myappointment',
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
