<template>
  <el-container>
    <el-header>
      <div id="menudiv">
        <el-menu ref="head" router :default-active="$router.path" class="el-menu-demo" mode="horizontal" text-color="#fff" background-color= "#378ee8"  active-text-color="#fff" style="margin-left: 50px ">
          <el-menu-item index="/index">检索首页</el-menu-item>
          <!-- <el-menu-item index="/browse">分类浏览</el-menu-item> -->
          <el-menu-item index="/newBook">新书速递</el-menu-item>
          <el-menu-item index="/hotBook">热门图书</el-menu-item>
          <el-menu-item @click="isLogin">我的图书馆</el-menu-item>
        </el-menu>
      </div>
      <div id="settingdiv">
        <el-button v-if="settingIsIcon" type="text" icon="el-icon-s-tools" circle style="margin-top: 3px" @click="setting()"></el-button>
        <el-button v-else type="text" style="margin-top: 8px;font-size: 18px" @click="setting()">{{libName}}</el-button>
        <el-dropdown v-if="card">
        <span class="el-dropdown-link" style="margin-top: 8px; margin-left: 15px; font-size: 18px">
          {{ card }} <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
          <el-dropdown-menu>
            <el-dropdown-item ><span @click="logout">注 销</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-main>
      <!-- <el-card class="contentCard"> -->
        <router-view></router-view>
      <!-- </el-card> -->
    </el-main>

    <el-dialog
      title="登录"
      :visible.sync="isClose"
      width="450px"
      :before-close="loginCancel"
      class="login-dialog">
      <el-form :model="param" :rules="rules" ref="login" label-width="0px">
        <el-form-item prop="num" class="input_class">
          <el-input v-model="param.num" class="w-50 m-2" placeholder="请输入用户名">
            <template #prefix>
              <img src="../assets/img/username.png" style="height: 20px;width: 18px;margin-top: 9px;margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pwd" class="input_class">
          <el-input class="w-50 m-2" placeholder="请输入密码" v-model="param.pwd" @keyup.enter="login()"
                    type="password">
            <template #prefix>
              <img src="../assets/img/passwordIcon.png"
                   style="height: 20px;width: 18px;margin-top: 9px;margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <div id="input_btn" class="input_class" style="margin-top: 60px;margin-bottom: 40px;text-align: center">
          <el-button type="success" @click="login()">登&nbsp;录</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="设置"
      :show-close="this.showClose"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span style="margin-left: 10px">组织</span>
      <el-select v-model="organizationID" placeholder="请选择组织" clearable style="margin-left: 10px;">
        <el-option
          v-for="address in libraryAddresses"
          :key="address.StrID"
          :label="address.StrName"
          :value="address.StrID">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureChange()">确 定</el-button>
      </span>
    </el-dialog>
    <el-footer style="padding-top: 30px;">©2009-2021 杭州三阅信息技术有限公司</el-footer>
</el-container>
</template>

<script>
import { getAllLibs, login } from '@/api'

function receiveMessage (event) {
  try {
    var lib = JSON.parse(event.data)
    console.log(lib)
    localStorage.setItem('library', lib.libId)
    console.log(localStorage.getItem('library'))
    location.reload()
  } catch (error) {
    console.error('Error parsing JSON data:', error)
    // alert('数据获取失败，请手动登录！')
  }
}
window.addEventListener('load', function () {
  window.addEventListener('message', receiveMessage, false)
})
export default {
  data () {
    return {
      param: {
        num: '',
        pwd: ''
      },
      rules: {
        num: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      lib: '',
      isClose: false,
      card: localStorage.getItem('card') || null,
      activePath: '',

      libraryAddresses: [],
      organizationID: '',
      showClose: false,
      settingIsIcon: true,
      libName: '',
      isUrlProcessed: false
    }
  },
  created () {
    console.log(localStorage.getItem('library'))
    this.searchLibraryAddresses()
    // 监听父组件传递的事件，控制弹窗显示
  },
  computed: {
    dialogVisible: {
      get () {
        return this.$store.state.dialogVisible // 从 Vuex 获取弹窗状态
      },
      set (value) {
        this.$store.commit('setDialogVisible', value) // 通过 Vuex mutation 更新弹窗状态
      }
    }
  },
  destroyed () {
    // 清理事件监听
  },
  mounted () {
  },
  methods: {
    sureChange () {
      console.log(localStorage.getItem('library'))
      localStorage.setItem('library', this.organizationID)
      this.searchLibraryAddresses()
      this.dialogVisible = false
      location.reload()
    },
    async searchLibraryAddresses () {
      // const { data: res } = await this.$http.get('search/list')
      // if (res.meta.status !== 200) {
      //   return this.$message.console.error(res.meta.msg)
      // }
      // console.log(res.data)
      // this.libraryAddresses = res.data
      const result = await getAllLibs()
      if (result.ok) {
        this.libraryAddresses = result.msg
        this.changeLib()
      } else {
        return this.$message(result.msg)
      }
    },
    changeLib () {
      // eslint-disable-next-line no-unused-vars
      this.organizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
      if (this.organizationID != null && this.organizationID !== '') {
        for (var i in this.libraryAddresses) {
          // eslint-disable-next-line eqeqeq
          if (this.libraryAddresses[i].StrID == this.organizationID) {
            this.libName = this.libraryAddresses[i].StrName
            this.settingIsIcon = false
          }
        }
      } else {
        this.libName = ''
        this.settingIsIcon = true
      }
    },
    // 判断是否为移动端
    isMobile () {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    setting () {
      this.dialogVisible = true
    },
    handleClose (done) {
      var Url = new URLSearchParams(window.location.search)
      Url.delete('lib')
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async logout () {
      console.log('logout')
      localStorage.removeItem('card')
      localStorage.removeItem('token')
      await this.message('注销成功')
      console.log(this.$route.path)
      if (this.$route.path === '/myLib') {
        this.$router.push('/index')
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.$router.go(0)
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.$router.go(0)
      }
    },
    message (msg) {
      const h = this.$createElement
      this.$notify({
        title: '提示',
        message: h('i', { style: 'color: teal' }, msg)
      })
    },
    isLogin () {
      if (localStorage.getItem('library') === null || localStorage.getItem('library') === '') {
        this.$store.dispatch('openDialog') // 触发 Vuex action 打开弹窗
      } else if (localStorage.getItem('token') === null) {
        this.isClose = true
      } else {
        this.$router.push('/myLib')
      }
    },
    async login () {
      try {
        if (this.param.num === '' || this.param.pwd === '') {
          this.message('不能提交空选项')
          return
        }
        console.log(localStorage.getItem('library'))
        const loginData = {
          StrOrganizationID: localStorage.getItem('library') == null ? '' : localStorage.getItem('library'),
          StrReaderCardNumber: this.param.num,
          StrPassword: this.param.pwd
        }
        const response = await login(loginData)
        console.log('登录成功', response)
        if (response.ok) {
          localStorage.setItem('token', response.token)
          localStorage.setItem('card', this.param.num)
          this.isClose = false
          await this.message('登录成功')
          this.$router.push('/myLib')
          await new Promise(resolve => setTimeout(resolve, 1000))
          this.$router.go(0)
        } else {
          this.message('登录失败,' + response.msg)
        }
        // 处理登录成功的逻辑，比如跳转页面或存储用户信息
      } catch (error) {
        console.error('登录失败', error)
        this.message('登录失败,' + error)
        // 处理登录失败的逻辑，比如显示错误信息
      }
    },
    loginCancel () {
      this.lib = ''
      this.param.pwd = ''
      this.param.card = ''
      this.isClose = false
    }
  }
}
</script>

<style lang="less" scoped>
  .el-menu-item{
    border-bottom: none !important;
    font-size: 15px !important;
  }
  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0,0.2) !important;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none !important;
  }
  #menudiv{
    display: inline-block;
  }
  #settingdiv{
    display: inline-block;
    float: right;
  }
  /deep/ #menudiv>.el-menu.el-menu--horizontal {
    border-bottom: none !important;
  }
  #settingdiv>.el-button--text{
    color: #ffffff;
  }
  #settingdiv>.el-button{
    font-size: 28px ;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #ffffff;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  .input-field {
    width: 100%;
    max-width: 400px; /* 最大宽度限制 */
    margin-bottom: 15px; /* 输入框间距 */
    border-radius: 5px; /* 圆角 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  }
  /* 整体对话框 */
  .login-dialog {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    border-radius: 16px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }

  /* 标题样式 */
  .login-dialog .el-dialog__header {
    background: linear-gradient(135deg, #00bcd4, #4caf50);
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 20px;
  }

  /* 对话框的内容区域 */
  .dialog-content {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 16px;
    box-sizing: border-box;
  }

  /* 输入框样式 */
  .input-group {
    margin-bottom: 20px;
    width: 100%;
  }

  .input-group label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
    color: #333;
    font-size: 14px;
  }

  .input-field {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    background: #fff;
  }

  .input-field:focus {
    border-color: #00bcd4; /* 聚焦时的边框颜色 */
    box-shadow: 0 0 5px rgba(0, 188, 212, 0.5);
  }

  /* 按钮样式 */
  .dialog-footer {
    text-align: right;
    padding: 10px;
  }

  .cancel-btn {
    margin-right: 12px;
    color: #757575;
    border-color: #ddd;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }

  .confirm-btn {
    background-color: #4caf50;
    border-color: #4caf50;
    font-size: 14px;
    color: white;
    transition: background-color 0.3s ease;
  }

  .cancel-btn:hover {
    background-color: #f2f2f2;
  }

  .confirm-btn:hover {
    background-color: #45a049;
  }

  /* 按钮的焦点效果 */
  .cancel-btn:focus,
  .confirm-btn:focus {
    outline: none;
    box-shadow: 0 0 4px rgba(0, 188, 212, 0.6);
  }

  /* 响应式设计 */
  @media (max-width: 768px) {
    .login-dialog {
      width: 80%;
    }

    .dialog-content {
      padding: 15px;
    }

    .input-field {
      padding: 10px;
      font-size: 14px;
    }

    .confirm-btn {
      font-size: 12px;
    }

    .cancel-btn {
      font-size: 12px;
    }
  }
  .input-field ::v-deep .el-input__suffix {
    right: 10px; /* 调整小眼睛图标向左移动 */
  }

  .input-field ::v-deep .el-input__inner {
    padding-right: 30px; /* 调整输入框右侧内边距，避免文字与图标重叠 */
  }

  .input_class {
    width: 322px;
    margin: 30px auto;
  }
  /deep/ .el-button--success {
    background-color: #4be4d0;
    border-color: #4be4d0;
  }

</style>
