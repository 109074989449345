// 对于axios进行二次封装
import axios from 'axios'
import router from '@/router'
// 底下的代码也是创建axios实例
// 开发环境
let requests
if (process.env.NODE_ENV === 'development') {
  requests = axios.create({
    // 正式
    // baseURL: '/opac',
    // 测试
    baseURL: '/api',
    timeout: 60000
  })
}
if (process.env.NODE_ENV === 'production') {
  requests = axios.create({
    // 正式
    baseURL: '',
    // 测试
    // baseURL: '/api',
    timeout: 60000
  })
}

// 响应拦截器----当服务器手动请求之后，做出响应（相应成功）会执行的
requests.interceptors.response.use(
  (res) => {
    return res.data
  }, (err) => {
    localStorage.removeItem('token')
    localStorage.removeItem('card')
    alert('书籍信息服务器响应数据失败' + err)
    router.push('/index')
  }
)

export default requests
