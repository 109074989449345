import { render, staticRenderFns } from "./BookList.vue?vue&type=template&id=6548d902&scoped=true"
import script from "./BookList.vue?vue&type=script&lang=js"
export * from "./BookList.vue?vue&type=script&lang=js"
import style0 from "./BookList.vue?vue&type=style&index=0&id=6548d902&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6548d902",
  null
  
)

export default component.exports