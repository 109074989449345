<template>
  <el-container>
    <el-header>
      <el-menu ref="head" router :default-active="$router.path" class="el-menu-demo head" mode="horizontal" text-color="#fff" active-text-color="#fff" background-color="#378ee8"  style="width: 100%;">
        <el-menu-item index="/index">检索首页</el-menu-item>
        <!-- <el-menu-item index="/browse">分类浏览</el-menu-item> -->
        <el-menu-item index="/newBook">新书速递</el-menu-item>
        <el-menu-item index="/hotBook">热门图书</el-menu-item>
        <el-menu-item @click="isLogin">我的图书馆</el-menu-item>
      </el-menu>
    </el-header>
    <el-main style="position: relative">
      <!-- <el-card class="contentCard"> -->
      <router-view></router-view>
      <!-- </el-card> -->
    </el-main>
    <el-footer style="padding-top: 8%; width: 100%; font-size: 0.5rem">
      ©2009-2021 杭州三阅信息技术有限公司
    </el-footer>
    <el-dialog title="登录" :visible.sync="dialogVisible" width="100%" :before-close="loginCancel" class="login-dialog-mobile">
      <el-form :model="param" :rules="rules" ref="login" label-width="0px">
        <el-form-item prop="num" class="input_class">
          <el-input v-model="param.num" class="w-50 m-2" placeholder="请输入用户名">
            <template #prefix>
              <img src="../assets/img/username.png" style="height: 20px; width: 18px; margin-top: 9px; margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pwd" class="input_class">
          <el-input class="w-50 m-2" placeholder="请输入密码" v-model="param.pwd" @keyup.enter="login()" type="password">
            <template #prefix>
              <img src="../assets/img/passwordIcon.png" style="height: 20px; width: 18px; margin-top: 9px; margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <div id="input_btn" class="input_class" style="margin-top: 60px; margin-bottom: 40px; text-align: center">
          <el-button type="success" @click="login()">登&nbsp;录</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      title="设置"
      :show-close="this.showClose"
      :visible.sync="dialogVisibleSeting"
      width="80%">
      <span style="margin-left: 10px">组织</span>
      <el-select v-model="searchForm1.StrOrganizationIDs[0]" placeholder="请选择组织" clearable style="margin-left: 10px;max-width: 70%" >
        <el-option
          v-for="address in libraryAddresses"
          :key="address.StrID"
          :label="address.StrName"
          :value="address.StrID">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisibleSeting = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sureChange()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { getAllLibs, login } from '@/api'

export default {
  data () {
    return {
      param: {
        num: '',
        pwd: ''
      },
      rules: {
        num: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      isClose: false,
      lib: '',
      dialogVisible: false,
      libraryAddresses: [],
      organizationID: '',
      showClose: false,
      settingIsIcon: true,
      libName: '',
      dialogVisibleSeting: false,
      searchForm1: {
        KeyName: 'all',
        StrKeywords: '',
        Sort: '-1',
        OrderBy: 'DTCreatedDate',
        OnLib: false,
        Distinct: true,
        Page: 1,
        PerPage: 10,
        StrOrganizationIDs: []
      }
    }
  },
  created () {
    // var path = window.location.href
    // this.activePath = path.substring(path.lastIndexOf('/'))
    // this.activePath = this.activePath.split('?')[0]
    this.activePath = this.$router.currentRoute.path
    this.$router.path = this.activePath
  },
  mounted () {
    this.searchLibraryAddresses()
    this.searchForm1.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    this.searchForm1.StrKeywords = sessionStorage.getItem('searchContent') == null ? '' : sessionStorage.getItem('searchContent')
    const keyname = sessionStorage.getItem('keyName')
    if (keyname !== null && keyname !== '') {
      this.searchForm1.KeyName = keyname
    } else {
      this.searchForm1.KeyName = 'all'
    }
  },
  methods: {
    sureChange () {
      localStorage.setItem('library', this.searchForm1.StrOrganizationIDs[0])
      this.changeLib()
      this.dialogVisibleSeting = false
    },
    changeLib () {
      this.searchForm1.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
      if (this.searchForm1.StrOrganizationIDs[0] != null && this.searchForm1.StrOrganizationIDs[0] !== '') {
        for (var i in this.libraryAddresses) {
          if (this.libraryAddresses[i].StrID === this.searchForm1.StrOrganizationIDs[0]) {
            this.libName = this.libraryAddresses[i].StrName
            this.settingIsIcon = false
          }
        }
      } else {
        this.libName = ''
        this.settingIsIcon = true
      }
    },
    async searchLibraryAddresses () {
      // const { data: res } = await this.$http.get('search/list')
      // if (res.meta.status !== 200) {
      //   return this.$message.console.error(res.meta.msg)
      // }
      // console.log(res.data)
      // this.libraryAddresses = res.data
      const result = await getAllLibs()
      if (result.ok) {
        this.libraryAddresses = result.msg
        this.changeLib()
      } else {
        return this.$message(result.msg)
      }
    },
    // isLogin () {
    //   if (localStorage.getItem('token') === null) {
    //     this.dialogVisible = true
    //   } else {
    //     this.$router.push('/myLib')
    //   }
    // },
    isLogin () {
      if (localStorage.getItem('library') === null || localStorage.getItem('library') === '') {
        // this.$router.push('/index')
        // this.$store.dispatch('openDialog') // 触发 Vuex action 打开弹窗
        this.dialogVisibleSeting = true
      } else if (localStorage.getItem('token') === null) {
        this.dialogVisible = true
      } else {
        this.$router.push('/myLib')
      }
    },
    async logout () {
      console.log('logout')
      localStorage.removeItem('card')
      localStorage.removeItem('token')
      await this.message('注销成功')
      console.log(this.$route.path)
      if (this.$route.path === '/myLib') {
        this.$router.push('/index')
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.$router.go(0)
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.$router.go(0)
      }
    },
    message (msg) {
      const h = this.$createElement
      this.$notify({
        title: '提示',
        message: h('i', { style: 'color: teal' }, msg)
      })
    },
    async login () {
      try {
        if (this.num === '' || this.pwd === '') {
          this.message('不能提交空选项')
          return
        }
        console.log(localStorage.getItem('library'))
        const loginData = {
          StrOrganizationID: localStorage.getItem('library') == null ? '' : localStorage.getItem('library'),
          StrReaderCardNumber: this.param.num,
          StrPassword: this.param.pwd
        }
        const response = await login(loginData)
        console.log('登录成功', response)
        if (response.ok) {
          localStorage.setItem('token', response.token)
          localStorage.setItem('card', this.param.num)
          this.dialogVisible = false
          await this.message('登录成功')
          this.$router.push('/myLib')
          await new Promise(resolve => setTimeout(resolve, 1000))
          this.$router.go(0)
        } else {
          this.message('登录失败,' + response.msg)
        }
        // 处理登录成功的逻辑，比如跳转页面或存储用户信息
      } catch (error) {
        console.error('登录失败', error)
        this.message('登录失败,' + error)
        // 处理登录失败的逻辑，比如显示错误信息
      }
    },
    loginCancel () {
      this.lib = ''
      this.param.pwd = ''
      this.param.card = ''
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-item {
  border-bottom: none !important;
  font-size: 1rem !important;
  padding: 0 10px !important; /* 调整左右内边距 */
  box-shadow: none !important; /* 去除阴影 */
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
  box-shadow: none !important; /* 去除阴影 */
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
  box-shadow: none !important; /* 去除阴影 */
}
.head {
  height: 100%;
  border: none;
  display: flex;
  justify-content: space-around;
}
#settingdiv {
  position: absolute;
  left: 0;
  bottom: 0;
}
/deep/ #menudiv > .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
#settingdiv > .el-button--text {
  color: #606266;
}
#settingdiv > .el-button {
  font-size: 18px;
}
.input_class {
  width: 322px;
  margin: 30px auto;
}
/deep/ .el-button--success {
  background-color: #4be4d0;
  border-color: #4be4d0;
}

.head .el-menu-item {
  margin-right: -10px;  /* 设置菜单项之间的间隔 */
}


</style>
